import { HttpInterceptorFn } from "@angular/common/http";
import { inject } from "@angular/core";
import { LoaderService } from "../../../../app/shared/services/loader.service";
import { finalize } from "rxjs/operators";
let activeRequests = 0;

export const loaderInterceptor: HttpInterceptorFn = (req, next) => {
  const loaderService = inject(LoaderService);
  let modifiedReq = req;

  const requestLoadingParam = req.params.get("loading-component");
  if (requestLoadingParam !== "false") {
    if (activeRequests === 0) {
      loaderService.showLoader();
    }
    activeRequests++;

    return next(req).pipe(
      finalize(() => {
        activeRequests--;
        if (activeRequests === 0) {
          loaderService.stopLoader();
        }
      }),
    );
  } else {
    const updatedParams = req.params.delete("loading-component");
    modifiedReq = req.clone({ params: updatedParams });
    return next(modifiedReq);
  }
};
