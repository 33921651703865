import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { IResponseTexts } from "../../models/ConfigTexts";
import { IResponseConstants } from "../../models/ProductsCda";
import { IResponseGetProductConf } from "../../models/Config.app";
import { ENDPOINTS } from "../../../shared/utils/constants/Endpoints";
import { VALUES_GET_PRODUCT_CONF } from "../../../shared/utils/constants/Values-product-conf";
import { environment } from "../../../../environments/environment";
import { KeyStorage } from "../../../../app/shared/utils/enums/key-storage";
import { ICardManagementMean } from "../../models/Management-means";
import { IResponseCitiesCoverage } from "../../models/CitiesCoverage";

@Injectable({
  providedIn: "root",
})
export class ConfigService {
  private hasTechnicalError$ = new BehaviorSubject<boolean>(false);

  constructor(private readonly http: HttpClient) {}

  getConfigTexts(): Observable<IResponseTexts> {
    return this.http
      .get<IResponseTexts>(environment.urlAssets + ENDPOINTS.texts)
      .pipe(
        tap((response) =>
          sessionStorage.setItem(KeyStorage.keyTexts, JSON.stringify(response)),
        ),
      );
  }

  getProductsCDA(): Observable<IResponseConstants> {
    return this.http.get<IResponseConstants>(
      environment.urlAssets + ENDPOINTS.constants,
    );
  }
  getProductConfiguration(): Observable<IResponseGetProductConf> {
    return this.http
      .get<IResponseGetProductConf>(
        environment.urlBase + ENDPOINTS.getProductConfig,
      )
      .pipe(
        map((data) => {
          data.parameters = data.parameters.filter((item) => {
            const name = item.name.replace(
              environment.prefixProductConfiguration,
              "",
            );
            return VALUES_GET_PRODUCT_CONF.hasOwnProperty(name);
          });
          return data;
        }),
      );
  }

  getHasTechnicalError(): Observable<boolean> {
    return this.hasTechnicalError$.asObservable();
  }

  setHasTechnicalError(hasError: boolean) {
    this.hasTechnicalError$.next(hasError);
  }

  getManagementMeans(): Observable<ICardManagementMean[]> {
    return this.http
      .get<IResponseConstants>(environment.urlAssets + ENDPOINTS.constants, {
        params: { "loading-component": "false" },
      })
      .pipe(
        map((data) => {
          return data.managament_means;
        }),
      );
  }

  getCoverageCities(): Observable<IResponseCitiesCoverage> {
    return this.http.get<IResponseCitiesCoverage>(
      environment.urlAssets + ENDPOINTS.coverageCities,
    );
  }
}
