import { inject } from "@angular/core";
import { ResolveFn } from "@angular/router";
import { ConfigService } from "../../services/config/config.service";
import { Observable } from "rxjs";
import { IResponseCitiesCoverage } from "../../models/CitiesCoverage";

export const coverageCitiesResolver: ResolveFn<
  Observable<IResponseCitiesCoverage>
> = (route, state) => {
  return inject(ConfigService).getCoverageCities();
};
