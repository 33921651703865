import { Injectable } from "@angular/core";
@Injectable({
  providedIn: "root",
})
export class TealiumUtagService {
  scriptSrcTealium = "";
  scriptSrcTealiumSync = "";

  // Typically set "noview" flag (no first page automatic view event) to true for Single Page Apps (SPAs)
  constructor() {
    (window as any).utag_cfg_ovrd = { noview: true };
    (window as any).utag_data = {};
  }

  // Generic script loader with callback
  getScript(src: string, asynchronous: boolean, callback: () => void) {
    const d = document;
    const fn = () => {
      //this is intentional
    };
    const o = { callback: callback || fn };
    let s: any;
    let t: any;

    if (typeof src === "undefined") {
      return;
    }

    s = d.createElement("script");
    s.language = "javascript";
    s.type = "text/javascript";
    s.async = asynchronous;
    s.charset = "utf-8";
    s.src = src;
    if (typeof o.callback === "function") {
      s.addEventListener(
        "load",
        () => {
          o.callback();
        },
        false,
      );
    }
    t = d.getElementsByTagName("script")[0];
    if (t) t.parentNode.insertBefore(s, t);
  }

  // Config settings used to build the path to the utag.js file
  setConfig(config: {
    account: string;
    profile: string;
    environment: string;
    sync: boolean;
  }) {
    if (
      config.account !== undefined &&
      config.profile !== undefined &&
      config.environment !== undefined &&
      config.sync !== undefined
    ) {
      const nameScript = config.sync ? "utag.sync.js" : "utag.js";
      const scriptSrc =
        "https://tags.tiqcdn.com/utag/" +
        config.account +
        "/" +
        config.profile +
        "/" +
        config.environment +
        "/" +
        nameScript;

      if (config.sync) {
        this.scriptSrcTealiumSync = scriptSrc;
      } else {
        this.scriptSrcTealium = scriptSrc;
      }
    }
  }

  createTagSync() {
    if (this.scriptSrcTealium === "") {
      return;
    } else {
      this.getScript(this.scriptSrcTealiumSync, false, () => {
        return;
      });
    }
  }

  // Data layer is optional set of key/value pairs
  track(tealiumEvent: string, data?: any) {
    if ((window as any).utag === undefined) {
      this.getScript(this.scriptSrcTealium, true, () => {
        (window as any).utag.track(tealiumEvent, data);
      });
    } else {
      (window as any).utag.track(tealiumEvent, data);
    }
  }

  view(data?: any) {
    this.track("view", data);
  }

  link(data?: any) {
    this.track("link", data);
  }
}
