export interface IDataOpenCDA {
  cdtTerm: string;
  requiredGMF: boolean;
  hasInsurance: boolean;
  accountSelected: Accounts;
  authInd: boolean;
  createCDA: ICreationCDA;
  feePerInsurance: string;
  desc: string;
  stateProvName: STATEPROV;
  artCode: number;
  ProductType: string;
  appsyncTimeToResponse: number;
  tagAval: string | null;
}
export interface ICreationCDA {
  accountNumber: string;
}

export enum STATEPROV {
  OFFICE = "O",
  WANTTWOCARDS = "A",
  WANTCARDDEBITDIGITAL = "D",
  DEFAULT = "",
}
export type Accounts = "CUENTA_SIMPLE" | "CUENTA_PRO" | "";
