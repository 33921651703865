import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LoaderService {
  private $showLoader = new BehaviorSubject<boolean>(false);
  public load: boolean = false;

  get loading(): boolean {
    return this.load;
  }

  getStatusLoader() {
    return this.$showLoader.asObservable();
  }

  showLoader() {
    this.$showLoader.next(true);
  }

  stopLoader() {
    document.body.classList.remove("loading");
    return this.$showLoader.next(false);
  }

  public stopLoading() {
    document.body.classList.add("loading");
    document.body.classList.remove("loading");
  }
}
