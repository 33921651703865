import { inject } from "@angular/core";
import { ResolveFn } from "@angular/router";
import { ConfigService } from "../services/config/config.service";
import { KeyStorage } from "../../../app/shared/utils/enums/key-storage";

export const configtextsResolver: ResolveFn<any> = (route, state) => {
  const configService = inject(ConfigService);
  const texts = sessionStorage.getItem(KeyStorage.keyTexts);
  const arrayTexts = JSON.parse(texts as string);
  if (arrayTexts !== null && ((arrayTexts.AvvTextsCda.length > 0) as unknown)) {
    return true;
  }
  return configService.getConfigTexts();
};
