import { Pipe, PipeTransform } from "@angular/core";
import { IArgsPipeTexts, IConfigTexts, Lang } from "../models/ConfigTexts";

@Pipe({
  name: "maptextsbylang",
  standalone: true,
})
export class MaptextsbylangPipe implements PipeTransform {
  transform(label: string, valueReplace: IArgsPipeTexts): string {
    const configTexts = sessionStorage.getItem("TextsCDA");
    const keyLang = this.getKeyLang(valueReplace.lang);
    let textComponent = label;
    let textsList = JSON.parse(configTexts as string)
      .AvvTextsCda as IConfigTexts[];
    if (textsList?.length > 0) {
      let dataText = textsList.find((text) => text.label === label);
      if (dataText) {
        const selectText = dataText[keyLang];
        textComponent = this.validReplace(
          selectText,
          valueReplace.valueReplace as string,
        );
        return textComponent;
      }
    }
    return textComponent;
  }

  getKeyLang(lang: Lang): keyof IConfigTexts {
    const valuesLang: Record<string, keyof IConfigTexts> = {
      es: "value_es",
      en: "value_en",
    };
    return valuesLang[lang];
  }
  validReplace(value: string, valueReplace: string) {
    if (value.includes("{{ value }}") && valueReplace && valueReplace != "") {
      return value.replace("{{ value }}", valueReplace);
    }
    return value;
  }
}
