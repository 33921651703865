import { Injectable } from "@angular/core";
import { Accounts, IDataOpenCDA, STATEPROV } from "../../models/DataOpenCDA";

@Injectable({
  providedIn: "root",
})
export class DataCdaService {
  private readonly dataCDA: IDataOpenCDA;
  constructor() {
    this.dataCDA = {
      accountSelected: "",
      cdtTerm: "1",
      hasInsurance: false,
      requiredGMF: false,
      authInd: false,
      feePerInsurance: "14.800",
      createCDA: {
        accountNumber: "",
      },
      stateProvName: STATEPROV.DEFAULT,
      desc: "",
      artCode: 0,
      ProductType: "",
      appsyncTimeToResponse: 120,
      tagAval: null,
    };
  }

  setAccount(account: Accounts) {
    this.dataCDA.accountSelected = account;
  }

  setProductType(productType: string) {
    this.dataCDA.ProductType = productType;
  }

  setCdtTerm(data: string) {
    this.dataCDA.cdtTerm = data;
  }

  setAppsyncTimeToResponse(seconds: number) {
    this.dataCDA.appsyncTimeToResponse = seconds;
  }

  setHasInsurance(hasInsurance: boolean) {
    this.dataCDA.hasInsurance = hasInsurance;
  }

  setTagAval(tagAval: string) {
    this.dataCDA.tagAval = tagAval;
  }

  setRequiredGMF(data: boolean) {
    this.dataCDA.requiredGMF = data;
  }
  setAuthInd(data: boolean) {
    this.dataCDA.authInd = data;
  }

  setDesc(data: string) {
    this.dataCDA.desc = data;
  }

  build() {
    return this.dataCDA;
  }

  setAccountNumber(number: string) {
    this.dataCDA.createCDA.accountNumber = number;
  }
  setStateProvName(stateProv: STATEPROV) {
    this.dataCDA.stateProvName = stateProv;
  }
  setArtCode(code: number) {
    this.dataCDA.artCode = code;
  }
}
