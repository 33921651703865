export const VALUES_GET_PRODUCT_CONF: Record<string, string> = {
  "product-configuration/digital-deposit-limit": "digital-deposit-limit",
  "product-configuration/digital-deposit-exoneration":
    "digital-deposit-exoneration",
  "product-configuration/digital-deposit-exoneration-uvt":
    "digital-deposit-exoneration-uvt",
  "product-configuration/handling-fee": "handling-fee",
  "product-configuration/e2e-cipher": "cipher-2e2",
};
export const DIGITAL_DEPOSIT = "product-configuration/digital-deposit-limit";
export const DIGITAL_DEPOSIT_EXONERATE =
  "product-configuration/digital-deposit-exoneration";
export const DIGITAL_DEPOSIT_EXONERATE_UVT =
  "product-configuration/digital-deposit-exoneration-uvt";
export const HANDLING_FEE = "product-configuration/handling-fee";

export const VALUES_RADIO_BUTTON_SURE = [
  {
    id: "yes-sure",
    text: "txt_choice_yes_sure",
    value: true,
    checked: false,
    name: "radio",
  },

  {
    id: "no-sure",
    name: "radio",
    text: "txt_choice_no_sure",
    value: false,
    checked: false,
  },
];
