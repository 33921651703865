import { HttpInterceptorFn } from "@angular/common/http";
import { ExcludedPaths } from "../../../../app/shared/utils/constants/Endpoints";
import { inject } from "@angular/core";
import { UserDataService } from "../../services/user-data/user-data.service";

export const headersInterceptor: HttpInterceptorFn = (req, next) => {
  const excludedPaths = ExcludedPaths;
  const userDataService = inject(UserDataService);
  const isExcluded = excludedPaths.some((path) => req.url.includes(path));
  if (isExcluded) {
    return next(req);
  } else {
    const reqWithHeaders = req.clone({
      setHeaders: {
        "x-adl-document-type":
          userDataService.customerData.userInformation.documentType,
        "x-adl-document-number":
          userDataService.customerData.userInformation.documentNumber,
        "transaction-id": userDataService.customerData.transactionId,
        "x-adl-channel": "bavv-pasivo-cda-masivo",
      },
    });

    return next(reqWithHeaders);
  }
};
