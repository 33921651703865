import { CUSTOM_ELEMENTS_SCHEMA, Component, inject } from "@angular/core";
import { MaptextsbylangPipe } from "../../../../app/core/pipes/maptextsbylang.pipe";
import { ConfigService } from "../../../../app/core/services/config/config.service";
import { CommonModule } from "@angular/common";
import { environment } from "../../../../environments/environment";
import { Router } from "@angular/router";

@Component({
  selector: "app-technical-error",
  standalone: true,
  imports: [MaptextsbylangPipe, CommonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: "./technical-error.component.html",
  styleUrl: "./technical-error.component.scss",
})
export class TechnicalErrorComponent {
  //Inyeccion de dependencias
  configService = inject(ConfigService);
  router = inject(Router);
  urlAssets = environment.urlAssets;

  closeModal() {
    this.configService.setHasTechnicalError(false);
    this.router.navigateByUrl("../");
  }
}
