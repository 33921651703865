export enum BasePath {
  Base = "/bancadigital/abrir-cuenta-ahorro",
}
export enum Routes {
  OfferProduct = "/oferta-producto",
  chooseSure = "/elegir-seguro",
  otp = "/otp",
  homeChannels = "../",
  summary = "/resumen",
}

export const routesGuardInfoCDA = [
  Routes.OfferProduct,
  Routes.chooseSure,
  Routes.otp,
  Routes.summary,
];
